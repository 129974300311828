











import {Vue, Component, Prop} from 'vue-property-decorator';
import ProductCoreModel from '@/modules/products/models/ProductCoreModel';
import UISettings from '@/app/lib/settings/UISettings';
import Button from '@/shared/resources/components/buttons/Button.vue';

@Component({
  components: {
    Button,
  },
})
export default class ProductGeneralDetails extends Vue {
  /**
   * Props
   */
  @Prop() private product!: ProductCoreModel;

  /**
   * Methods
   * @private
   */
  private showModal() {
    UISettings.openSideModal('ProductPhotosCarousel.vue', { product: this.product });
  }
}
